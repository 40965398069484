@mixin list--checklist($root) {
    &#{$root}--checklist {
        content: 'checklist';

        li {
            padding-left: 2.25em;
            list-style: none;
            margin-bottom: 1em;
            position: relative;

            &:before {
                position: absolute;
                left: 0.5em;
                top: 0.2em;
                font-size: 0.8em;
                font-family: $font-icons;
                color: var(--color-corp-id-2);
                content: '\e80d';
            }
        }
    }
}
