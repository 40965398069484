.popup {
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background: var(--popup-backgroundColor);
    overflow: visible;

    &__item {
        z-index: 101;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate3d(-50%, -50%, 0);
        overflow: hidden;
        border-radius: $border-radius;
    }

    &--visible {
        display: block;
    }
}
