:root {
    --accordion-backgroundColor: rgb(var(--color-white));
    --accordion--dark-backgroundColor: rgb(var(--color-canvas__neutral));
    --accordion-borderColor: rgb(var(--color-gray__neutral));
}

%title {
    padding: 0.6em;
    font: normal 1.3em var(--font-primary);
}

.accordion {
    $root: &;
    display: flex;
    flex-direction: column;
    border-radius: var(--borderRadius);
    background: var(--accordion-backgroundColor);

    &__header {
        @extend %title;
        display: flex;
        align-items: baseline;
    }

    &__title {
        @extend %title;
        display: none;
    }

    &__container {
        display: none;
        padding: 0.875em;
    }

    &__icon {
        margin-left: auto;
    }

    &--open {
        & > #{$root}__header {
            display: none;
        }

        & > #{$root}__title {
            display: flex;
            align-items: baseline;
        }

        & > #{$root}__container {
            display: block;
        }
    }

    &--dark {
        background: var(--accordion--dark-backgroundColor);
    }

    &--border {
        border: 1px solid var(--accordion-borderColor);

        #{$root}__title {
            box-shadow: 0 1px 0 0 var(--accordion-borderColor);
        }
    }
}
