.divider {
    border: none;
    border-top: 1px solid rgba(var(--color-gray__neutral), 1);
    margin: 1em 0;

    &--spacing-medium {
        margin: 2em 0;
    }

    &--spacing-large {
        margin: 3em 0;
    }

    &--spacing-xlarge {
        margin: 5em 0;
    }

    &--no-border {
        border: none;
    }
}
