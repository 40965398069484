.icon-label {
    display: flex;
    align-items: center;

    &__icon {
        font-size: 1.6em;
        margin-right: 0.3em;
        opacity: 0.9;
    }

    &__label {
        font-family: $font-lato-bold;
    }
}
