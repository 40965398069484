//TODO refactor naming conventions for variables

:root {
    --dropdown--gray__display-backgroundColor: rgb(var(--color-gray__lighten20));
    --dropdown--gray__display-borderColor: rgb(var(--color-gray__lighten20));
    --dropdown--gray__display--focus-backgroundColor: rgb(var(--color-gray__lighten10));
    --dropdown--gray__display--focus-borderColor: rgb(var(--color-gray__lighten10));
    --dropdown__divider-backgroundColor: rgb(var(--color-gray__lighten10));
    --dropdown__layover-backgroundColor: rgba(var(--color-foreground), 0.65);
    --dropdown--focus__display-backgroundColor: rgb(var(--color-gray__lighten20));
    --dropdown__display-backgroundColor__light: rgb(var(--color-white));
    --dropdown__display--hover-backgroundColor__light: rgb(var(--color-gray__lighten30));
    --dropdown__display-backgroundColor__dark: rgb(var(--color-canvas__lighten5));
    --dropdown--display-backgroundColor: rgb(var(--color-white));
    --dropdown--display--focus-backgroundColor: rgb(var(--color-gray__lighten10));
    --dropdown__display-box-shadow__light: var(--boxShadow);
    --dropdown__display-border: 1px solid rgba(var(--color-gray__neutral), 1);
    --dropdown__display-border-bottom: 1px solid rgba(var(--color-gray__neutral), 1);
    --dropdown__display-border-radius: var(--borderRadius);
    --dropdown__display--focus-backgroundColor__light: rgb(var(--color-gray__lighten30));
    --dropdown__display--disabled-backgroundColor__light: rgb(var(--color-gray__lighten20));
    --dropdown__display--disabled-backgroundColor: rgba(var(--color-gray__lighten20), 1);
    --dropdown--display-border-radius: var(--borderRadius);
    --dropdown__display-color__light: var(--input-color);
    --dropdown__display-border__light: 1px solid rgb(var(--color-gray__lighten10));
    --dropdown__display--focus-border__light: 1px solid rgb(var(--color-gray__lighten10));
    --dropdown__display--focus-border-bottom__light: 1px solid rgb(var(--color-gray__lighten10));
    --dropdown__display--hover-border__light: 1px solid rgb(var(--color-gray__lighten10));
    --dropdown__display--hover-border-bottom__light: 1px solid rgb(var(--color-gray__lighten10));
    --dropdown__item-backgroundColor: rgb(var(--color-white));
    --dropdown__item-backgroundColor__dark: rgb(var(--color-gray__darken30));
    --dropdown__item-color__dark: rgb(var(--color-white));
    --dropdown__pointer-backgroundColor__dark: rgb(var(--color-gray__darken30));
    --dropdown__pointer-backgroundColor__light: rgb(var(--color-white));
    --dropdown__pointer-borderBottomColor: rgb(var(--color-gray__lighten20));
    --dropdown__list-item-title-backgroundColor__dark: rgb(var(--color-gray__darken25));
    --dropdown__list-item-title-color__dark: rgb(var(--color-white));
    --dropdown__list-item__button-color: rgb(var(--color-gray__darken20));
    --dropdown__list-item__button-color--hover-backgroundColor: rgb(var(--color-canvas__lighten5));
    --dropdown__list-item__button-color--focus-backgroundColor: rgb(var(--color-canvas__lighten15));
}

.dropdown {
    $root: &;
    $dropdown-height: 2em;
    position: relative;

    &--arrow-left {
        #{$root}__pointer {
            left: 2em;
        }
    }

    &--arrow-right {
        #{$root}__pointer {
            left: auto;
            right: 2em;
        }
    }

    &--light-display {
        #{$root}__display {
            background: var(--dropdown__display-backgroundColor__light);
        }
    }

    &--gray {
        #{$root}__display {
            background: var(--dropdown--gray__display-backgroundColor);
            border-color: var(--dropdown--gray__display-borderColor);

            &:focus {
                background: var(--dropdown--gray__display--focus-backgroundColor);
                border-color: var(--dropdown--gray__display--focus-borderColor);
            }
        }
    }

    &--no-border {
        #{$root}__display {
            border: none;
        }
    }

    &--dark {
        #{$root}__item {
            background: var(--dropdown__item-backgroundColor__dark);
            color: var(--dropdown__item-color__dark);
            padding: 0.8em;
        }

        #{$root}__pointer {
            background: var(--dropdown__pointer-backgroundColor__dark);
            z-index: 2;

            &:after,
            &:before {
                border-bottom-color: var(--dropdown__item-backgroundColor__dark);
            }
        }

        #{$root}__list-item {
            opacity: 0.5;
            padding: 0.4em 0.3em 0.4em 1.8em;
            border-radius: var(--borderRadius);

            &--title {
                margin-bottom: 0.4em;
                color: var(--dropdown__list-item-title-color__dark);
            }

            &--selected {
                padding-left: 0.2em;
                opacity: 1;

                &:before {
                    font-family: 'fq-icons';
                    content: '\e80d';
                    padding-right: 0.25em;
                }
            }

            &:not(#{$root}__list-item--title) {
                cursor: pointer;

                &:hover {
                    //background: darken(#7a848d, 10%);
                    background: var(--dropdown__list-item-title-backgroundColor__dark);
                    opacity: 1;
                }
            }
        }
    }

    &--small {
        font-size: 0.85em;
    }

    &--visible {
        display: block;

        #{$root}__layover {
            z-index: 998;
            display: flex;

            @include bp($screen-sm-min) {
                background: none;
            }
        }

        #{$root}__display,
        #{$root}__pointer,
        #{$root}__item {
            z-index: 999;
            display: flex;
        }
    }

    &--light {
        #{$root}__display {
            background: var(--dropdown__display-backgroundColor);
            box-shadow: var(--dropdown__display-box-shadow__light);
            border-radius: var(--dropdown--display-border-radius);
            border: var(--dropdown__display-border);
            border-bottom: var(--dropdown__display-border-bottom);

            &[disabled] {
                background: var(--dropdown__display--disabled-backgroundColor__light);
            }

            &:focus {
                background: var(--dropdown__display--focus-backgroundColor__light);
                border: var(--dropdown__display--focus-border__light);
                border-bottom: var(--dropdown__display--focus-border-bottom__light);
            }

            &:hover {
                background: var(--dropdown__display--hover-backgroundColor__light);
                border: var(--dropdown__display--hover-border__light);
                border-bottom: var(--dropdown__display--hover-border-bottom__light);
            }
        }
    }

    &__item {
        position: absolute;
        top: 3.4em;
        right: 0;
        display: none;
        border: var(--dropdown-border);
        border-radius: var(--borderRadius);
        box-shadow: var(--boxShadow);
        padding: 1em 0;
        flex-direction: column;
        width: auto;
        max-width: 22em;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: pre;
        background: var(--dropdown__item-backgroundColor);

        &--visible {
            display: flex;
        }

        &--to-left {
            right: auto;
            left: 0;
        }

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: -1em;
            width: 100%;
            height: 2em;
            background: rgba($color-black, 0);
        }
    }

    &__divider {
        height: 0.06em;
        background: var(--dropdown__divider-backgroundColor);
        margin: 0.75em 1em;
    }

    &__layover {
        background: var(--dropdown__layover-backgroundColor);
        display: none;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    &__pointer {
        content: &;
        position: absolute;
        top: 1px;
        left: calc(50% - 0.16em);
        width: 0.61em;

        height: 0.61em;
        background: var(--dropdown__pointer-backgroundColor__light);

        &:after,
        &:before {
            bottom: 100%;
            left: 50%;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
        }

        &:after {
            border-color: #ffffff00;
            border-bottom-color: var(--dropdown__pointer-backgroundColor__light);
            border-width: 0.53em;
            margin-left: -0.53em;
        }

        &:before {
            border-color: #c9cdd100;
            border-bottom-color: var(--dropdown__pointer-borderBottomColor);
            border-width: 0.6em;
            margin-left: -0.6em;
        }
    }

    &__display {
        display: flex;
        justify-content: space-between;
        position: relative;
        align-items: center;
        font-size: 1em;
        width: 100%;
        overflow: hidden;
        padding: 0.5em 2em 0.48em 0.85em;
        text-align: left;
        color: var(--dropdown__display-color__light);
        border-radius: var(--dropdown--display-border-radius);
        border-bottom: var(--dropdown__display-border__light);
        background: var(--dropdown--display-backgroundColor);
        font-family: var(--input-fontFamily);
        white-space: pre;

        &:after {
            color: #959ea6;
            font-family: $font-icons;
            content: '\e806';
            font-size: 1.55em;
            line-height: 1;
            margin: 0.1em -1.3em 0 auto;
        }

        &:focus {
            background: var(--dropdown--focus__display-backgroundColor);
        }

        &[disabled] {
            background: var(--dropdown__display--disabled-backgroundColor);
            border: 1px solid transparent;
        }

        & > span {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre;
        }
    }

    &__list-item {
        list-style: none;
        display: flex;

        &:not(&:first-of-type) {
            &:hover {
                background: var(--dropdown__list-item__button-color--hover-backgroundColor);
            }

            &:focus {
                background: var(--dropdown__list-item__button-color--hover-backgroundColor);
            }
        }

        &__button {
            text-decoration: none;
            color: var(--dropdown__list-item__button-color);
            width: 100%;
            margin: 0;
            min-height: 2em;
            font-size: 1em;
            border: none;
            background: none;
            appearance: none;
            text-align: left;
            padding: 0.5em 1em;
            white-space: pre;
            font-family: var(--font-secondary);
        }

        .formfield {
            margin-bottom: 0;
            width: 100%;

            &__icon {
                left: 2em;
            }

            &__input {
                width: calc(100% - 2.4em);
                margin: 0 auto;
                margin-bottom: 0.6em;
            }
        }

        .checkbox,
        .radio,
        .link {
            padding: 0.5em 1.2em;
            margin-bottom: 0;
            width: 100%;

            &__wrapper {
                align-items: center;
                display: flex;
                min-height: 2em;
                width: 100%;
            }
        }

        &:hover {
            #{$root}__list-item__button,
            .checkbox,
            .radio,
            .link {
                background: var(--dropdown__list-item__button-color--hover-backgroundColor);
            }
        }
    }
}
