.fab {
    $root: &;

    &__button {
        @extend .button;
        position: fixed;
        transition: bottom 0.3s;
        right: 2rem;
        bottom: -5rem;
        background: rgba(var(--color-secondary__neutral), 1);
        width: 2em;
        height: 2em;
        border-radius: 100%;
        color: rgba(var(--color-white), 1);
        z-index: 101;
        font-size: 2em;

        &__icon {
            position: absolute;
            left: calc(50% - 0.64em);
            top: calc(50% - 0.5em);
        }
    }

    &__icon {
        margin-left: 0.8em;
    }

    &__button-caption {
        display: block;
        text-indent: -8000px;
    }

    &__overlay {
        display: none;
        opacity: 0;
        transition: all 0.3s ease;
        z-index: 100;
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 100;
        background: rgba(var(--color-black), 0.4);
        overflow: visible;
    }

    &__menu {
        bottom: 8rem;
        right: 2rem;
        text-align: right;
        position: absolute;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
    }

    &__option {
        opacity: 0;
        transition: all 0.3s ease;
        background: rgba(var(--color-white), 1);
        border-radius: 3em;
        padding: 0.9em 1em;
        line-height: 1;
        box-shadow: 0 0 7px rgba(var(--color-black), 0.3);
        margin-bottom: 1em;
        display: inline-block;
        font-family: var(--font-secondary);
    }

    &--opened {
        #{$root}__overlay {
            display: block;
            opacity: 1;
        }

        #{$root}__option {
            opacity: 1;
        }
    }

    &--visible {
        #{$root}__button {
            bottom: 3.8rem;
        }
    }
}
