.fixed-menu {
    $root: &;

    &__layover {
        transition: all 0.3s ease;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba($color-black, 0.3);
        display: none;
        opacity: 0;
        z-index: 10;
    }

    &__group-list {
        transition: all 0.3s ease;
        position: fixed;
        width: 100%;
        bottom: -100%;
        z-index: 11;
        left: 50%;
        transform: translateX(calc(-50% + 1em));
    }

    &__menu-list {
        width: 100%;
        background: $color-white;
        border-radius: 0.4em;
        margin-bottom: 1.5em;
        max-width: 24em;
        box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.175);
    }

    &__list-item {
        font-size: em(19);
        padding: 0.632em 1em;
        text-align: center;
        color: var(--color-corp-id-2);
        cursor: pointer;
        border-bottom: 1px solid $color-gray-light;
        &:last-child {
            border: none;
        }
    }

    &--open {
        #{$root}__group-list {
            bottom: 0;
        }
    }
}
