@mixin stepper-modifier($root, $iconCode, $color) {
    #{$root}__item {
        &--marked {
            color: $color;
            font-family: $font-remo-bold;
            #{$root}__bullet {
                background: $color;
                border-color: $color;
                box-shadow: 0 0 3px rgba($color, 0.5);
                &:after {
                    content: $iconCode;
                }
            }
        }
    }
}
.stepper {
    display: flex;
    $root: &;

    &__item {
        max-width: 9em;
        padding: 0 0.85em;
        position: relative;
        text-align: center;

        &:first-child {
            margin-left: 0;

            #{$root}__line {
                display: none;
            }
        }

        &--filled {
            #{$root}__bullet {
                background: $color-gray;
            }

            #{$root}__line {
                border-style: solid;
            }
        }

        &--marked {
            #{$root}__bullet {
                transform: scale(1.5);
                background: $color-gray;
                position: relative;

                &:after {
                    color: $color-white;
                    font-family: $font-icons;
                    position: absolute;
                    left: 0;
                    top: 0;
                    font-size: 0.45em;
                    line-height: 1.35;
                }
            }

            #{$root}__line {
                border-style: solid;
            }
        }
    }

    &__bullet {
        margin: 0 auto 0.7em;
        display: block;
        width: em(10);
        height: em(10);
        border: 1px solid $color-gray;
        background: lighten($color-gray, 15%);
        border-radius: 100%;
    }

    &__line {
        display: block;
        width: calc(100% - 1.85em);
        height: 2px;
        position: absolute;
        right: calc(50% + 0.9em);
        top: 0.35em;
        border-bottom: 2px dashed lighten($color-gray, 20%);
    }

    &--checked {
        @include stepper-modifier($root, '\e80d', var(--color-secondary__neutral));
    }
    &--notification {
        @include stepper-modifier($root, '\e80d', var(--color-notification__neutral));
    }

    &--confirmed {
        @include stepper-modifier($root, '\e80d', var(--color-confirm__neutral));
    }

    &--pending {
        @include stepper-modifier($root, '\e80d', var(--color-black));
    }
}
