.tabs {
    display: flex;
    flex-direction: column;
    background: $color-gray-light;
    border-radius: 0 0 #{$border-radius} #{$border-radius};

    &__title {
        font-size: 1.28em;
        font-family: $font-remo-bold;
        padding: 0.28em 1em 0;
    }

    &__nav {
        background: $color-gray-mid;
        padding: 0.75em 0 0 0;
        display: flex;
    }

    &__nav-item {
        padding: 0.5em 1.2em 0.75em;
        border-radius: #{$border-radius} #{$border-radius} 0 0;
        border-right: 1px solid $color-gray-light;
        cursor: pointer;

        &:last-of-type {
            border: none;
        }

        &__close {
            float: right;
            padding: 0 0 0 0.35em;
        }

        &--current {
            background: $color-gray-light;
        }
    }

    &__content {
        padding: 1em 1.28em;
        display: none;
        &--current {
            display: block;
        }
    }

    &__footer {
        padding: 1.28em 0 0.28em;
        border-top: 1px solid lighten($color-gray, 10%);
        margin: 1.28em 0 0 0;
    }
}
