:root {
    --link-color: rgba(var(--color-secondary__neutral));
    --link-color--secondary: rgba(var(--color-tertiary__neutral));
}

.link {
    color: var(--link-color);
    text-decoration: none;
    cursor: pointer;

    &--secondary {
        color: var(--link-color--secondary);
    }

    [class^='icon-'] {
        margin: 0 0.5em -0.16em 0;
        width: 1em;
        height: 1em;
    }
}
