.rangeinput {
    display: grid;
    grid-template: auto / 50% 50%;
    position: relative;
    margin-bottom: 1.25em;

    &--column-view {
        grid-template: auto auto / auto;
    }

    &__label {
        padding: 0.8em 0;
        font: normal 1em var(--font-primary);
        @include bp($screen-md-min) {
            font: normal 1em var(--font-primary);
        }
    }

    .formfield {
        &__input-wrapper {
            width: 100%;
        }
    }

    &__fields {
        box-shadow: 0 1px 2px 0 #82888b80;
        display: flex;
        background: $color-white;
        border: 1px solid $color-gray-light;
        border-radius: $border-radius;
        position: relative;

        &:focus-within {
            box-shadow: 0 1px 2px 1px #82888b4d;
        }

        & > div {
            width: 50%;
            margin: 0;
            &:first-child {
                border-right: 1px solid #e4e4e4;
            }
        }
    }
    .input {
        box-shadow: none;
        border: none;
        border-radius: 0;
        width: 100%;
        background: none;
    }
}
