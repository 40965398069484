.layover-page {
    $root: &;
    transition: all 0.3s ease;
    background: rgba(var(--color-canvas__neutral), 1);
    display: flex;
    flex-direction: column;

    &--fixed {
        position: fixed;
        z-index: 11;
        width: 100%;
        height: 100%;
    }

    &--top {
        top: 100%;
    }

    &--bottom {
        bottom: -100%;
    }

    &--left {
        left: -100%;
    }

    &--right {
        right: 100%;
    }

    &--is-open {
        &#{$root}--top {
            top: 0;
        }

        &#{$root}--bottom {
            bottom: 0%;
        }

        &#{$root}--left {
            left: 0;
        }

        &#{$root}--right {
            right: 0;
        }
    }

    &__close {
        font-size: 1.4em;
    }

    &__title {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    &__header {
        font-family: var(--font-secondary);
        align-items: center;
        justify-content: space-between;
        background: rgba(var(--color-primary__neutral), 1);
        color: rgba(var(--color-white), 1);
        height: 4.687em;
        display: flex;
        padding: 0 1em;
        position: relative;
    }

    &__content {
        padding: 1em 3em 4em;
        overflow: auto;
    }
}
