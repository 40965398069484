:root {
    --selectable-card--hover-border-color: rgba(var(--color-tertiary__neutral), 0.8);
    --selectable-card--hover-backgroundColor: rgba(var(--color-tertiary__neutral), 0.1);
    --selectable-card-backgroundColor: rgba(var(--color-tertiary__neutral), 0.03);
    --selectable-card-border-radius: var(--borderRadius);
    --selectable-card-border-color: rgba(var(--color-tertiary__neutral), 0.5);
    --selectable-card__title-color: rgb(var(--color-tertiary__neutral));
    --selectable-card__p-color: rgb(var(--color-gray__darken10));
}

.selectable-card {
    $root: &;
    border: 1px solid var(--selectable-card-border-color);
    background: var(--selectable-card-backgroundColor);
    border-radius: var(--selectable-card-border-radius);
    text-align: center;
    margin: 0 0 1em;
    padding: 1.8em 1em;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    &--align-left {
        align-items: flex-start;
    }

    h1,
    h2,
    h3,
    h4,
    h5 {
        font-family: var(--font-primary-bold);
        color: var(--selectable-card__title-color);
        margin-bottom: 0.5em;
        font-size: 1em;
    }

    p {
        color: var(--selectable-card__p-color);
        margin-bottom: 0.5em;
    }

    &--narrow {
        padding: 0.75em 1em;
    }

    &__options {
        position: absolute;
        right: 0;
        top: 0;
        display: grid;
        grid-template: auto / auto;
        gap: 0.5em;
        padding: 0.5em;
        border-left: 1px solid var(--selectable-card-border-color);
        height: 100%;
        color: var(--selectable-card__title-color);

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background: var(--selectable-card-border-color);
            display: block;
        }
    }

    &:hover,
    &--selected {
        border-color: var(--selectable-card--hover-border-color);
        background: var(--selectable-card--hover-backgroundColor);

        #{$root}__options {
            border-color: var(--selectable-card--hover-border-color);

            &:after {
                border-color: var(--selectable-card--hover-border-color);
            }
        }
    }

    &--with-options {
        padding: 0.75em 3.5em 0.75em 1em;

        & > p,
        & > h1,
        h2,
        h3,
        h4,
        h5 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            width: 100%;
        }
    }

    &--transparent {
        background: none;
        border-color: rgba(var(--color-gray__neutral), 1);

        h1,
        h2,
        h3,
        h4,
        h5 {
            color: rgba(var(--color-secondary__neutral), 1);
        }

        p {
            color: rgba(var(--color-gray__darken15), 1);
        }

        & #{$root}__options {
            button {
                color: rgba(var(--color-gray__darken10), 0.9);
            }

            border-color: rgba(var(--color-gray__neutral), 1);

            &:after {
                background: rgba(var(--color-gray__neutral), 1);
            }
        }

        &:hover {
            border-color: var(--selectable-card--hover-border-color);
            background: none;

            & #{$root}__options {
                border-color: var(--selectable-card--hover-border-color);

                button {
                    color: var(--selectable-card--hover-border-color);
                }

                &:after {
                    background: var(--selectable-card--hover-border-color);
                }
            }
        }
    }
}
