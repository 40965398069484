.upload {
    $root: &;

    &__files {
        font-size: 1em;
        line-height: 1.8;
        margin-bottom: 1.5em;

        li {
            display: flex;
            justify-content: space-between;
        }
    }

    &__dropzone {
        display: flex;
        background: rgba(var(--color-canvas__neutral), 1);
        border: 1px dashed rgba(var(--color-gray__darken10), 1);
        padding: 2em 1em;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        &--dragover {
            background: rgba(var(--color-canvas__darken5), 1);
        }
    }

    &__input {
        position: absolute;
        bottom: 0;
        left: calc(50% - 110px);
        opacity: 0;
    }

    &__message {
        position: relative;
        text-align: center;
    }

    &__button {
        width: 100%;
        @include bp($screen-sm-min) {
            display: none;
        }
    }

    &--hidden {
        #{$root}__dropzone {
            overflow: hidden;
            height: 0;
        }
    }
}
