:root {
    --toggler-backgroundColor: rgb(var(--color-gray__lighten10));
    --toggler__bullet-backgroundColor: rgb(var(--color-white));
    --toggler--focus__bullet-backgroundColor: rgb(var(--color-gray__neutral));
}

.toggler {
    $root: &;
    color: var(--selectbox-color);

    &__label {
        display: flex;
    }
    &__text {
        font: var(--selectbox__text-font);
        display: flex;
        position: relative;
        padding: 0 0 0 3.5em;

        &:before {
            position: absolute;
            content: '';
            top: em(-1);
            left: 0;
            width: em(40);
            height: em(20);
            display: block;
            border-radius: 3em;
            border: none;
            transition: background 0.2s;
            background: var(--toggler-backgroundColor);
        }

        &:after {
            position: absolute;
            content: '';
            top: em(2);
            left: em(2);
            opacity: 1;
            transition: all 0.2s;
            background: var(--toggler__bullet-backgroundColor);
            width: 1em;
            height: 1em;
            border-radius: 1em;
            box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        }
    }
    &__item {
        opacity: 0;
        transform: scale(0);
        position: absolute;

        &:focus {
            & + #{$root}__text:after {
                background: var(--toggler__bullet-backgroundColor);
            }

            & + #{$root}__text:before {
                background: var(--toggler--focus__bullet-backgroundColor);
            }
        }
        &:checked {
            & + #{$root}__text:after {
                left: em(23);
            }
            & + #{$root}__text:before {
                background: rgba(var(--color-tertiary__neutral), 1);
            }
        }

        &[disabled] {
            & + #{$root}__text {
                color: lighten($selectbox-color, 25%);
                &:before {
                    border-color: lighten($selectbox__item-border-color, 5%);
                }

                &:after {
                    opacity: 0.3;
                }
            }
        }
    }

    &__message {
        font-size: 0.85em;
        padding: 0 0 0 4.2em;
        @include clearfix();
    }
}
