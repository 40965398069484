.tooltip {
    $root: &;
    color: rgba(var(--link-color), 1);
    position: relative;
    &:hover {
        #{$root}__tip {
            opacity: 1;
            top: -2.5em;
        }
    }
    &__tip {
        position: absolute;
        z-index: 999;
        white-space: nowrap;
        top: 9999px;
        left: 50%;
        transform: translateX(-50%);
        background: rgba(var(--color-gray__darken20), 1);
        box-shadow: var(--boxShadow);
        border-radius: var(--boxRadius);
        color: rgba(var(--color-white), 1);
        padding: 0.4em 0.6em;

        opacity: 0;
        transition: opacity 0.4s ease-out;

        &:before {
            top: 100%;
            left: 50%;
            border: solid transparent;
            content: '';
            height: 0;
            width: 0;
            position: absolute;
            pointer-events: none;
            border-color: rgba(0, 0, 0, 0);
            border-top-color: #7a848d;
            border-width: 6px;
            margin-left: -6px;
        }
    }
}
