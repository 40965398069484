.lds-ring {
    display: inline-block;
    position: relative;
    width: 3em;
    transform: scale(0.5);
    transform-origin: 0 0;
    height: 1.5em;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 3em;
    height: 3em;
    border: 6px solid rgba(var(--color-secondary__neutral), 1);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: rgba(var(--color-secondary__neutral), 1) transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading {
    display: flex;
    align-content: center;
}
