:root {
    --input-fontSize: 1em;
    --input-backgroundColor: rgb(var(--color-white));
    --input-fontFamily: var(--font-secondary);
    --input-color: rgba(var(--color-gray__darken20), 1);
    --input-border: 1px solid rgba(var(--color-gray__neutral), 1);
    --input-border-bottom: 1px solid rgba(var(--color-gray__neutral), 1);
    --input-borderRadius: var(--borderRadius);
    --input-boxShadow: inset 0 1px 2px 0 rgb(130, 136, 139, 0.2);
    --input--disabled-background: rgba(var(--color-gray__lighten20), 1);
    --input--disabled-background: rgba(var(--color-gray__lighten20), 1);
    --input--focus-border-color: rgba(var(--color-gray__neutral), 1);
    --input--focus-border-bottom-color: rgba(var(--color-gray__neutral), 1);
    --input--hover-border-color: rgba(var(--color-gray__lighten10), 1);
    --input--hover-border-bottom-color: rgba(var(--color-gray__lighten10), 1);
    --input__placeholder-color: rgba(var(--color-gray__neutral), 1);
}

.input {
    font: normal var(--input-fontSize) var(--input-fontFamily);
    padding: $input-padding;
    background: var(--input-backgroundColor);
    border: var(--input-border);
    border-bottom: var(--input-border-bottom);
    color: var(--input-color);
    border-radius: var(--input-borderRadius);
    appearance: textfield;
    box-shadow: var(--input-boxShadow);

    &:hover {
        border-color: var(--input--hover-border-color);
        border-bottom-color: var(--input--hover-border-bottom-color);
    }

    &:focus {
        border-color: var(--input--focus-border-color);
        border-bottom-color: var(--input--focus-border-bottom-color);
    }

    @include placeholder {
        color: var(--input__placeholder-color);
    }

    &:disabled {
        background: var(--input--disabled-background);
        color: var(--input--disabled-color);
        border: 1px solid transparent;
        box-shadow: none;
    }

    &--align-right {
        text-align: right;
    }

    &--primary {
        background: rgba(var(--color-secondary__neutral), 1);
        color: var(--color-white);

        &:focus {
            background: rgba(var(--color-secondary__darken15), 1);
        }
    }
}
