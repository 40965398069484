//system layout core colors
:root {
    // these colors are going to be used in a near future
    --color-primary__neutral: 72, 72, 93;
    --color-primary__lighten25: 117, 117, 133;
    --color-primary__lighten50: 163, 163, 174;

    --color-secondary__neutral: 68, 78, 219;
    --color-secondary__lighten50: 161, 166, 237;
    --color-secondary__darken50: 33, 38, 109;
    --color-secondary__darken15: 34, 43, 177;

    --color-tertiary__neutral: 61, 176, 243;
    --color-tertiary__darken15: 41, 156, 213;
    --color-tertiary__darken50: 30, 87, 121;

    --color-foreground: 72, 72, 92;

    --color-white: 255, 255, 255;
    --color-gray__lighten30: 250, 250, 250;
    --color-gray__lighten20: 241, 241, 241;
    --color-gray__lighten15: 234, 234, 234;
    --color-gray__lighten10: 228, 228, 228;
    --color-gray__lighten5: 222, 222, 222;
    --color-gray__neutral: 217, 217, 217;
    --color-gray__darken10: 176, 176, 176;
    --color-gray__darken20: 148, 148, 148;
    --color-gray__darken25: 80, 80, 80;
    --color-gray__darken30: 72 72 72;
    --color-black: 0, 0, 0;

    --color-canvas__lighten15: 245, 244, 250;
    --color-canvas__lighten10: 245, 248, 250;
    --color-canvas__lighten5: 238, 249, 250;
    --color-canvas__neutral: 235, 241, 245;
    --color-canvas__darken5: 218, 229, 237;
    --color-canvas__darken10: 212, 217, 221;
    --color-canvas__darken15: 184, 205, 220;

    --color-confirm__neutral: 127, 182, 102;
    --color-confirm__darken15: 90, 140, 67;
    --color-notification__neutral: 245, 117, 87;
    --color-notification__darken15: 205, 77, 57;
    --color-notification__lighten25: 252, 216, 207;

    --color-status__created: 176, 181, 184;
    --color-status__submitted: 254, 210, 59;
    --color-status__approved: 127, 182, 102;
    --color-status__paid: 68, 177, 240;
    --color-status__rejected: 245, 117, 87;
    --color-status__on-hold: 0, 0, 0;

    --selectbox-color: rgb(var(--color-secondary__neutral));
    --selectbox__item-border-color: rgb(var(--color-gray__lighten10));
    --selectbox__item-backgroundColor: rgb(var(--color-black));
    --selectbox__item-border: 1px solid var(--selectbox__item-border-color);
    --selectbox-border-radius: var(--borderRadius);
    --selectbox-font: normal var(--input-fontSize) var(--input-fontFamily);
    --selectbox__text-padding: 0 0 0 2em;
    --selectbox__text-font: normal var(--input-fontSize) var(--input-fontFamily);
    --selectbox__message-padding: 0 0 0 2.5em;

    --popup-backgroundColor: rgba(var(--color-foreground), 0.8);
    --text-color: rgb(var(--color-gray__darken25));
}
