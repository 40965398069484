.spacer {
    border: none;
    border-bottom: 1px solid $color-gray-light;
    margin: 1em 0;
    @include clearfix();

    &--dark {
        border-color: $color-gray;
    }

    &--extra-space {
        margin: 1.5em 0;
    }

    &--large-space {
        margin: 2.5em 0;
    }
}
