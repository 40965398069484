.flatpickr {
    font-family: $font-lato;
    $root: &;

    .dayContainer {
        padding-top: 0.3em;
    }

    &-calendar {
        #{$root}-current-month {
            font-family: $font-lato;

            span.cur-month {
                font-family: $font-lato-bold;
            }
        }

        #{$root}-month,
        #{$root}-weekdays,
        #{$root}-weekday {
            background: var(--color-corp-id-3);
            color: $color-white;
            fill: var(--color-corp-id-3);
        }

        #{$root}-next-month,
        #{$root}-prev-month {
            & svg {
                fill: $color-white;
                color: $color-white;
            }

            &:hover {
                svg {
                    fill: $color-white;
                    color: $color-white;
                    opacity: 0.6;
                }
            }
        }

        #{$root}-innerContainer {
            font-family: $font-lato;
        }

        #{$root}-day {
            border: none;

            &.selected {
                background: var(--color-corp-id-3__l10);
            }

            &.startRange.startRange {
                &,
                &:hover {
                    background: var(--color-corp-id-3__l10);
                    color: $color-white;
                }

                & + .endRange:not(:nth-child(7n + 1)) {
                    box-shadow: -10px 0 0 var(--color-corp-id-3__l10);
                }
            }

            &.inRange {
                background: var(--color-corp-id-3__l10);
                color: $color-white;
                box-shadow: -6px 0 0 var(--color-corp-id-3__l10), 6px 0 0 var(--color-corp-id-3__l10);
                position: relative;
                z-index: 2;
            }

            &.endRange {
                background: var(--color-corp-id-3__l10);
                color: $color-white;
                border: 1px solid transparent;
            }
        }
    }
}
