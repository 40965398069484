@import './_list--checklist';
@import './list--definition';
@import './list--timeline';
.list-wrapper {
    $root: &;
    $bullet-size: 10px;

    &__header {
        font-size: 1em;
        border-bottom: 1px solid lighten($color-gray, 20%);
        padding: 0.6em 0 1em;
        margin-bottom: 1.2em;

        &--no-divider {
            border-bottom: none;
            padding-bottom: 1em;
        }

        & > * {
            margin: 0;
            padding: 0;
            font: normal 1.15em $font-remo-bold;
            color: darken($color-gray, 30%);
        }
    }
    @include list--checklist($root);
    @include list--definition($root);
    @include list--timeline($root, $bullet-size);
}
