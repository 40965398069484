.footer-items {
    $root: &;
    display: flex;
    margin: 0 -0.5em;

    & > * {
        margin: 0.5em;
    }

    &--stretch {
        & > * {
            flex: 1;
        }
    }
    &--column-stretch {
        display: flex;
        flex-direction: column;
        & > * {
            flex: 1;
        }
    }

    &--splitted {
        justify-content: space-between;
    }

    &--end {
        justify-content: flex-end;
    }

    &--start {
        justify-content: flex-start;
    }

    &--center {
        justify-content: center;
    }
}
