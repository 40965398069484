// @mixin showItem ($modifier) {

//     &#{$modifier} {
//         @content;
//     }

//     &--show {
//         opacity: 1;
//     }
// }

.toast {
    $root: &;
    transition: all 0.3s ease-in-out;
    color: rgba(var(--color-white), 1);
    background: rgba(var(--color-black), 0.85);
    padding: 1em;
    opacity: 0;
    position: fixed;
    left: 1em;
    bottom: -8em;
    z-index: 20;
    max-width: calc(100% - 2em);
    border-radius: var(--borderRadius);
    display: grid;
    gap: 1em;
    grid-template: auto/ auto auto;
    font-size: 0.9em;
    line-height: 1.7;

    @include bp($screen-sm-min) {
        max-width: 40em;
        font-size: 1em;
    }

    &__close {
        font-size: 1.8em;
        margin: -0.375em;
    }

    &--show {
        bottom: 1em;
        opacity: 1;
        cursor: pointer;
    }

    &--from-bottom {
        bottom: -8em;
        &#{$root}--show {
            bottom: 1em;
        }
    }

    &--from-bottom-right {
        bottom: -8em;
        left: auto;
        right: 1em;
        &#{$root}--show {
            bottom: 1em;
        }
    }

    &--from-top {
        bottom: auto;
        top: -8em;
        &#{$root}--show {
            top: 1em;
        }
    }

    &--from-top-right {
        bottom: auto;
        top: -8em;
        left: auto;
        right: 1em;
        &#{$root}--show {
            top: 1em;
        }
    }
}
