.avatar {
    $root: &;
    display: grid;
    grid-template: auto / auto;
    grid-gap: 1em;
    justify-items: center;

    &--inline {
        justify-items: flex-start;
        grid-template: auto / auto 1fr;

        #{$root}__title {
            margin: 0;
            align-self: center;
            white-space: pre;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
        }
    }

    &--medium {
        #{$root}__container {
            width: 4em;
            height: 4em;
        }
    }

    &--medium-small {
        #{$root}__container {
            width: 2.85em;
            height: 2.85em;
        }
    }

    &--small {
        #{$root}__container {
            width: 2em;
            height: 2em;
        }
    }

    &__container {
        font-size: 1em;
        width: 8.57em;
        height: 8.57em;
        border: 1px solid rgba(var(--color-white), 1);
        background: rgba(var(--color-gray), 1);
        border-radius: 100%;
        display: block;
        box-shadow: var(--boxShadow);
        overflow: hidden;
    }

    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
    }

    &__title {
        font-size: 1.14em;
        margin: 1.5em 0 0.5em;
    }

    &__description {
        font-size: 1em;
        color: rgba(var(--color-gray__darken20), 1);
    }
}
