@mixin list--definition($root) {
    &__list {
        dl {
            display: grid;
            grid-template: auto / auto 1fr;
            grid-gap: 0;
        }

        dt {
            padding: 0.6em;

            &:not(#{$root}__item--overview) {
                color: $color-gray;
            }
        }

        dd {
            padding: 0.6em;
            text-align: right;
            position: relative;

            &:not(#{$root}__item--overview) {
                color: rgba(var(--color-gray__darken30), 1);
            }
        }
        &--align-left {
            dl {
                grid-template: auto / minmax(1em, 1fr) 1fr;
            }

            dt,
            dd {
                text-align: left;
            }
        }
    }

    &__item {
        &--overview {
            color: darken($color-gray, 40%);
            font-family: $font-lato-bold;
        }
    }
}
