:root {
    --navigator-background-color: rgb(255, 255, 255);
    --navigator-font-family: var(--font-primary-bold);
    --navigator__submenu-font-family: var(--font-primary);
    --navigator__link-color: rgb(var(--color-gray__darken20));
    --navigator__link-color--hover: rgb(var(--color-tertiary__darken50));
    --navigator__link-background-color: rgba(var(--color-tertiary__neutral), 0.1);
    --navigator__child-link-font-family: var(--font-secondary);
    --navigator__child-link-font-family--hover: var(--font-secondary-bold);
}

.navigator {
    overflow: hidden;
    transition: all 0.3s;
    $root: &;
    font-size: 1em;
    width: 16em;
    height: 100vh;
    background-color: var(--navigator-background-color);
    box-shadow: var(--boxShadow);
    font-size: 1.14em;
    font-family: var(--navigator-font-family);
    position: relative;
    left: -16em;

    &--to-right {
        right: 100%;
    }

    &--open {
        left: 0;
    }

    &__logo {
        transform: translateX(0);
        transition: all 0.3s;
        opacity: 1;
        width: 100%;
    }

    &__logo--mini {
        position: absolute;
        top: 2.1em;
        left: 4em;
        transition: all 0.3s;
        opacity: 0;
        width: 3em;
    }

    &--mini {
        left: -3.7em;
        width: 3.7em;

        &#{$root}--open {
            left: 0;
        }

        #{$root}__link-text {
            transform: translateX(-4em);
            opacity: 0;
        }
        #{$root}__nav {
            padding: 2.1em 0.5em;
        }

        #{$root}__logo--mini {
            opacity: 1;
            left: 0.5em;
        }

        #{$root}__logo {
            opacity: 0;
            transform: translateX(-4em);
        }

        & a:first-of-type {
            padding: 0.552em 0 0 0.8em;
            margin: 0.5em 0;

            &:hover {
                background-color: transparent;
            }
        }
    }

    &__link-text {
        transition: all 0.3s;
        opacity: 1;
        transform: translateX(0);
    }

    &__nav {
        transition: all 0.3s;
        padding: 2.1em 1.7em;
    }

    &__footer {
        position: absolute;
        bottom: 1em;
        left: 0;
        padding: 0.422em 0.65em;
        width: calc(100% - 0.65em);
    }

    li {
        &:hover {
            ul {
                margin: 1em 0;
                display: block;
            }
        }
    }

    a {
        white-space: pre;
        color: var(--navigator__link-color);
        display: inline-block;
        padding: 0.422em 0.65em;
        transition: all 0.3s;
        width: 100%;
        border-radius: var(--borderRadius);
        font-family: var(--navigator__link-font-family);
        margin: 0 -0.5em;

        &:hover,
        &.active {
            color: var(--navigator__link-color--hover);
            background-color: var(--navigator__link-background-color);
        }
        i {
            opacity: 0.55;
            width: 1.5em;
            margin-right: 0.7em;
            display: inline-block;
        }
    }

    ul {
        margin-top: 4em;

        li {
            margin-bottom: 0.5em;
            ul {
                display: none;
                margin-top: 0;
                font-family: var(--navigator__submenu-font-family);
                overflow: hidden;
                margin-bottom: 1em;
                li {
                    margin-bottom: 0.2em;
                    & > a {
                        padding: 0;
                        padding-left: 2.8em;
                        margin-bottom: 0.3em;
                        font-family: var(--navigator__child-link-font-family);

                        &:hover,
                        &.active {
                            background-color: transparent;
                            font-family: var(--navigator__child-link-font-family--hover);
                        }
                    }
                }
            }
        }
    }
}
