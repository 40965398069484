.data-table {
    $root: &;
    background: rgba(var(--color-white), 1);
    border-radius: var(--borderRadius);
    color: rgba(var(--color-foreground), 1);
    display: flex;
    flex-direction: column;

    [data-hover-component] {
        display: none;
        position: absolute;
        right: 1em;
        z-index: 2;

        & > * {
            position: relative;
            z-index: 3;
        }
    }

    &--shadow {
        box-shadow: var(--box-shadow);
    }

    &--notification {
        #{$root}__content {
            color: rgba(var(--color-notification__neutral), 1);
        }
        #{$root}__column-title {
            display: none;
        }
    }

    &--no-shadow {
        box-shadow: none;
    }

    &__content {
        overflow: hidden;
        padding: 0.75em 0;
        margin: 0 -0.9em;
        border-top: none;
        border-bottom: none;
        display: flex;
        position: relative;
        background: rgba(var(--color-white), 1);

        .checkbox {
            display: block;
        }
    }

    &__column {
        position: relative;
        z-index: 1;
        flex: 1;

        &--check-table {
            flex: 0;
            width: 3.5em;
            padding-left: 0.5em;

            #{$root}__column-title {
                font-size: 1em;
                height: 1.6em;
            }

            #{$root}__column-title {
                margin-bottom: 1.2em;
            }
        }

        &--dragging {
            box-shadow: 0 0 5px rgba(var(--color-black), 0.3);
            opacity: 0.8;
        }

        &--editor {
            span[class*='icon'] {
                font-size: 1.2em;
                margin: 0 0.2em;
            }
        }

        &--hover-item {
            position: absolute;
            right: 1px;
            z-index: 0;
            width: calc(100% - 1px);

            #{$root}__component-wrapper {
                right: 0;
                padding: 1em;
            }

            #{$root}__item {
                padding: 0;
                min-height: 3em;
                border-radius: 4px;
                margin: 0 0.9em;

                &.hover {
                    background: rgba(var(--color-canvas__neutral), 0.7);
                    // background: red;
                }
            }

            #{$root}__item-value {
                position: absolute;
                height: 2.9em;
                display: flex;
                overflow: visible;

                // &:hover {
                //     &>*:first-of-type {
                //         display: block;
                //     }
                // }

                // &>*:first-of-type {
                //     position: absolute;
                //     padding: 0.6em 1em;
                //     right: 0;
                // }
            }
        }

        &:first-child:not(&--check-table) {
            padding-left: 1em;
        }

        &--highlight-table {
            flex: 0;
            padding-left: 0.5em;

            + #{$root}__column {
                &--check-table {
                    padding-left: 0em;
                }
            }

            #{$root}__column-title {
                font-size: 1em;
                height: 1.6em;
                padding: 0em;
                margin-bottom: 1.2em;
            }

            #{$root}__item {
                padding: 0em;
                &--highlighted {
                    padding-left: 1em;
                    & > * {
                        width: 0.125em;
                        padding-top: 0.125em;
                        padding-bottom: 0.125em;
                        background-color: rgba(var(--color-notification__neutral), 1);
                    }
                }
            }
        }
    }

    &__item-value {
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
    }

    &__column-title {
        height: 2em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgba(var(--color-gray__darken10), 1);
        font-family: var(--font-secondary);
        font-size: 0.92em;
        display: flex;
        padding: 0 1em;
        align-items: center;
        margin-bottom: 1em;
    }

    &__item {
        display: flex;
        min-height: 3em;
        padding: 0 1em;
        align-items: center;
        position: relative;

        & > span {
            position: relative;
            z-index: 3;
        }

        &--selected {
            background: rgba(var(--color-canvas__neutral), 0.7);
        }
    }

    &__edit {
        display: none;
        position: absolute;
        background: var(--color-canvas__neutral);
        right: 0;
        padding: 0.055em 2.4em 0 1em;

        &--visible {
            display: block;

            [class*='icon'] {
                float: right;
            }

            .dropdown {
                position: absolute;
                right: 1.3em;
                top: 2.4em;
                white-space: pre;

                &__item {
                    top: -1px;
                    width: 200px;
                    right: 0;
                }
            }
        }
    }

    &__footer {
        width: 100%;
        border-top: 1px solid $color-gray-light;
        padding: 0.75em 1em;
        display: flex;
        justify-items: center;
    }

    &__bulk-actions {
        display: flex;

        & > * {
            margin-right: 1em;
            line-height: 1;
        }

        &-indicator {
            margin: 0.3em 0.5em 0 0.33em;
            font-size: 2.1em;
            color: #b0b5b8;
        }
    }
}
