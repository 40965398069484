.radio {
    $root: &;
    font: var(--selectbox-font);

    &__label {
        display: flex;
    }
    &__text {
        display: flex;
        position: relative;
        padding: var(--selectbox__text-padding);

        &:before {
            position: absolute;
            content: '';
            top: 0.1em;
            left: 0.5em;
            width: 1em;
            height: 1em;
            display: block;
            border-radius: 100%;
            border: var(--selectbox__item-border);
        }

        &:after {
            position: absolute;
            content: '';
            top: 0.1em;
            left: 0.5em;
            width: 1em;
            height: 1em;
            border-radius: 100%;
            background: var(--selectbox__item-backgroundColor);
            opacity: 0.6;
            transform: scale(0);
            transition: all 0.2s;
        }
    }
    &__item {
        opacity: 0;
        display: none;

        &:checked {
            & + #{$root}__text:after {
                transform: scale(0.5);
            }
        }

        &[disabled] {
            & + #{$root}__text {
                color: var(--selectbox-color);
                &:before {
                    border-color: var(--selectbox__item-border-color);
                }

                &:after {
                    opacity: 0.3;
                }
            }
        }
    }

    &__message {
        font-size: var(--font-size-form-notification);
        padding: var(--selectbox__message-padding);
        @include clearfix();
    }
}
