@mixin font-face($font-family, $font-weight, $font-style, $file-name) {
    font-family: $font-family;
    font-display: swap;
    src: url('#{$fontPath}/#{$file-name}.eot');
    src: url('#{$fontPath}/#{$file-name}.eot?#iefix') format('embedded-opentype'),
        url('#{$fontPath}/#{$file-name}.woff') format('woff'), url('#{$fontPath}/#{$file-name}.ttf') format('truetype'),
        url('#{$fontPath}/#{$file-name}.svg##{$file-name}') format('svg');
    font-weight: $font-weight;
    font-style: $font-style;
}

@font-face {
    @include font-face('Remo Pro', 'normal', 'normal', 'RemoPro-Normal');
}

@font-face {
    @include font-face('Remo Pro Italic', 'normal', 'italic', 'RemoPro-NormalItalic');
}

@font-face {
    @include font-face('Remo Pro Bold', 'bold', 'normal', 'RemoPro-Bold');
}

@font-face {
    @include font-face('Remo Pro Bold Italic', 'bold', 'italic', 'RemoPro-BoldItalic');
}

@font-face {
    @include font-face('Lato', 'normal', 'normal', 'Lato-Normal');
}

@font-face {
    @include font-face('Lato Italic', 'normal', 'italic', 'Lato-NormalItalic');
}

@font-face {
    @include font-face('Lato Bold', 'bold', 'normal', 'Lato-Bold');
}

@font-face {
    @include font-face('Lato Bold Italic', 'bold', 'italic', 'Lato-BoldItalic');
}
