:root {
    --notification__header-background: var(--color-notification__neutral);
    --notification__header-color: var(--color-white);
}

.notification {
    $root: &;
    border-radius: $border-radius;
    overflow: hidden;
    @include clearfix();

    &__header {
        background: rgba(var(--notification__header-background), 0.75);
        padding: 0.86em 1em;
        color: rgba(var(--notification__header-color), 1);
    }

    &__header-icon {
        margin: $notification__header-icon-margin;
    }

    &__content {
        display: none;
        color: $notification__content-color;
        background: $notification__content-background;
        padding: 1em;
    }

    &__list {
        display: grid;
        grid-template: auto / auto 1fr;
        grid-gap: 0 1em;

        &-item {
            color: rgba(var(--color-notification__neutral), 1);
            display: flex;
            justify-content: space-between;
            align-items: center;

            &:after {
                content: '';
                background: $notification__list-item-border-color;
                width: 1.8em;
                height: 1px;
                display: inline-block;
                margin: 0.3em 0 0 1em;
            }
        }

        &-description {
            color: rgba(var(--color-gray__darken20), 1);
        }
    }

    &--collapsed {
        #{$root}__header {
            background: rgba(var(--color-notification__neutral), 1);
        }
        #{$root}__content {
            display: block;
        }
    }
}
