.overview-card {
    $root: &;
    display: flex;
    flex-direction: column;

    &__header {
        border-radius: $border-radius $border-radius 0 0;
        display: flex;
        justify-items: center;
        background: #dee4e8;
        padding: 0.75em;
        align-items: stretch;

        dl {
            display: grid;
            grid-template: auto / auto auto auto;
            gap: 0.3em 1em;
        }

        dt {
            color: rgba(var(--color-gray__darken20), 1);
        }

        dd {
            color: rgba(var(--color-gray__darken30), 1);
        }
    }

    &__toggler {
        font-size: 1.1em;
        height: 3em;
        width: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.3s;
        margin-right: 0.5em;
    }

    &__title {
        &--primary {
            margin-right: 0.75em;
            color: #959ea6;
        }

        &--secondary {
            margin-right: 0.75em;
        }
    }

    &__options {
        margin-left: auto;
        color: #818d97;
        font-size: 1.1em;
        letter-spacing: 0.3em;
    }

    &__content {
        transition: all 0.3s;
        height: 50em;
        overflow: auto;
        display: block;
        border-radius: 0 0 $border-radius $border-radius;
        box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.37);
    }

    &--open {
        #{$root}__toggler {
            transform: rotate(90deg);
        }

        &#{$root}--collapsible {
            #{$root}__content {
                height: 50em;
            }
        }
    }

    &--collapsible {
        #{$root}__content {
            height: 0;
        }
    }
}
