$color-corp-id-1-l-20: var(--color-corp-id-1-l-20);
$color-corp-id-1: var(--color-corp-id-1);

$color-corp-id-2: var(--color-corp-id-2);
$color-corp-id-2-d-15: var(--color-corp-id-2-d-15);

$color-corp-id-3: var(--color-corp-id-3);
$color-corp-id-3-d-5: var(--color-corp-id-3-d-5);
$color-corp-id-3-l-10: var(--color-corp-id-3-l-10);

$color-foreground: var(--color-foreground);
$color-foreground-d-25: var(--color-foreground-d-25);

$color-canvas: var(--color-canvas__neutral);
$color-canvas-d-15: var(--color-canvas-d-15);
