:root {
    --card-backgroundColor: rgb(var(--color-white));
    --card--dark-backgroundColor: rgb(var(--color-canvas__neutral));
    --card-border: rgb(var(--color-canvas__neutral));
    --card--dark-border: rgb(var(--color-gray__darken20));
}

.card {
    $root: &;
    background: var(--card-backgroundColor);
    border-radius: var(--borderRadius);
    position: relative;
    padding: 1em;
    display: flex;
    flex-direction: column;

    &__close-button {
        &:after {
            cursor: pointer;
            position: absolute;
            z-index: 2;
            right: 0.4em;
            top: 0.5em;
            font-family: $font-icons;
            font-size: 1.4em;
            color: var(--color-gray__neutral);
            content: '\e80f';
        }
    }

    &__header {
        position: relative;
        margin: -1em -1em 0;
        padding: 1em 1.5em 0.5em;

        @include bp($screen-sm-min) {
            padding: 1.5em;
        }

        &--divider {
            border-bottom: $card-border;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin: 0;
        }

        &--partial-divider {
            margin-bottom: 1.5em;

            &:after {
                content: '';
                position: absolute;
                display: block;
                width: 100%;
                padding-left: 2.5em;
                height: 1px;
                bottom: 0;
                background: var(--card-border);

                @include bp($screen-sm-min) {
                    width: calc(100% - 5em);
                }
            }
        }
    }

    &__content {
        margin: 0 -1em;
        padding: 0 1em;
    }

    &__footer {
        position: relative;
        margin: 0 -1em;
        padding: 0 1em 1.5em;

        &--divider {
            border-top: $card-border;
        }

        &--partial-divider {
            margin-top: 1.5em;

            &:before {
                content: '';
                position: absolute;
                display: block;
                padding-left: 2.5em;
                height: 1px;
                width: calc(100% - 1em);
                top: 0;
                background: var(--card-border);

                @include bp($screen-sm-min) {
                    width: calc(100% - 5em);
                }
            }
        }

        &--divider-shadow {
            border-top: $card-border;
            box-shadow: 1px 0 6px 1px rgba(var(--color-black), 0.1);
            margin-top: 1.5em;
        }
    }

    &--dark {
        background: var(--card--dark-backgroundColor);

        #{$root}__header {
            &--partial-divider {
                &:after {
                    background: var(--card--dark-border);
                }
            }
        }
    }

    &--transparent {
        background: none;
    }

    &--border {
        border: 1px solid rgba(var(--color-gray__neutral), 1);
    }

    &--shadow {
        box-shadow: $box-shadow;
    }
}
