:root {
    --formfield-padding: 0 0 1em;
    --formfield__label-color: rgb(var(--color-gray__darken30));
    --formfield__label-padding: 0.8em 0;
    --formfield__label-font: normal 1em var(--font-secondary);
    --formfield__message-font-size: var(--font-size-form-notification);
    --formfield__message-font: var(--font-secondary);
    --formfield__iconinput-padding: 0 0 0 2em;
    --formfield__message-padding: 0.5em 0 0 0.5em;
}

.formfield {
    $root: &;
    margin-bottom: 1.25em;

    @include clearfix();

    &:not(.formfield--column-view) > [class] {
        flex: 1;
    }

    &--column-view {
        flex-direction: column;
        justify-content: flex-start;

        #{$root}__input-wrapper {
            width: 100%;
        }

        #{$root}__input {
            width: 100%;
        }
        #{$root}__label {
            width: 100%;
        }
    }

    &__label {
        float: left;
        width: 50%;
        padding: var(--formfield__label-padding);
        font: normal 1em var(--font-secondary);
        color: var(--formfield__label-color);

        @include bp($screen-md-min) {
            font: normal 1em var(--font-primary);
        }
    }

    &__input-wrapper {
        float: right;
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: column;

        .toggler {
            margin-top: 0.75em;
        }
    }

    &__icon + &__input {
        padding-left: 2.75em;
    }

    &__icon {
        position: absolute;
        opacity: 0.5;
        left: 1em;
        top: 0.6em;
    }

    &__icon-secondary {
        position: absolute;
        right: 0.5em;
        top: 0.6em;
        padding-left: 0.4em;
        background: var(--input-backgroundColor);
    }

    &__message {
        padding: var(--formfield__message-padding);
        font-size: var(--formfield__message-font-size);
        font-family: var(--formfield__message-font);
        justify-content: flex-start;
    }

    &--notification {
        & *:not(#{$root}__label) {
            color: rgba(var(--color-notification__neutral), 1);
        }
    }
}
