:root {
    --button-border-radius: var(--borderRadius);
    --button-font-family: var(--font-primary-bold);
    --button-text-transform: none;
    --button-font-weight: normal;
    --button--primary-backgroundColor: rgb(var(--color-secondary__neutral));
    --button--primary-color: rgb(var(--color-white));
    --button--primary--focus-backgroundColor: rgb(var(--color-secondary__darken15));
    --button--secondary-backgroundColor: rgb(var(--color-canvas__neutral));
    --button--secondary-color: rgb(var(--color-secondary__neutral));
    --button--secondary--focus-backgroundColor: rgb(var(--color-canvas__darken10));
    --button--confirm-backgroundColor: rgb(var(--color-confirm__neutral));
    --button--confirm-color: rgb(var(--color-white));
    --button--confirm--focus-backgroundColor: rgb(var(--color-confirm__darken15));
    --button--notification-backgroundColor: rgb(var(--color-notification__neutral));
    --button--notification-color: rgb(var(--color-white));
    --button--notification--focus-backgroundColor: rgb(var(--color-notification__darken15));
    --button--blank-backgroundColor: rgb(var(--color-white));
    --button--blank-color: rgb(var(--color-secondary__neutral));
    --button--blank--focus-backgroundColor: rgb(var(--color-gray__lighten20));
    --button--gray-color: rgb(var(--color-gray__darken20));
    --button--gray-canvas-color: rgb(var(--color-secondary__neutral));
    --button--gray-transparent-color: rgb(var(--color-secondary__neutral));
    --button--disabled-color: rgb(var(--color-gray__darken10));
    --button--disabled-backgroundColor: rgb(var(--color-gray__lighten10));
}

@mixin addVariations($context, $variation) {
    &:focus {
        background: var(#{'--button--#{$variation}--focus-backgroundColor'});
    }

    &.#{$context}--outlined {
        background: none;
        border: 1px solid var(#{'--button--#{$variation}-backgroundColor'});
    }

    & .lds-ring {
        div {
            border-color: var(#{'--button--#{$variation}-color'}) transparent transparent transparent;
        }
    }
}

.button {
    $root: &;
    appearance: none;
    font: var(--button-font-weight) 1em var(--button-font-family);
    text-transform: var(--button-text-transform);
    cursor: pointer;
    padding: 0.912em;
    border-radius: var(--button-border-radius);
    border: none;
    line-height: 1;

    &--primary {
        background: var(--button--primary-backgroundColor);
        color: var(--button--primary-color);
        @include addVariations(button, primary);
    }

    &--secondary {
        background: var(--button--secondary-backgroundColor);
        color: var(--button--secondary-color);
        @include addVariations(button, secondary);
    }

    &--confirm {
        background: var(--button--confirm-backgroundColor);
        color: var(--button--confirm-color);
        @include addVariations(button, confirm);
    }

    &--notification {
        background: var(--button--notification-backgroundColor);
        color: var(--button--notification-color);

        @include addVariations(button, notification);
    }

    &--blank {
        background: var(--button--blank-backgroundColor);
        color: var(--button--blank-color);
        @include addVariations(button, blank);
    }

    &--gray {
        background: rgba(var(--color-gray__neutral), 1);
        color: rgba(var(--color-gray__darken20), 1);
        @include addVariations(button, gray);
    }

    &--gray-transparent {
        background: none;
        color: rgba(var(--color--gray__darken20), 1);
        @include addVariations(button, gray-transparent);
    }

    &--gray-canvas {
        background: var(--color--canvas);
        color: var(--color--secondary);
        @include addVariations(button, gray-canvas);
    }

    &--icon-only {
        padding: 1em 0.77em;

        #{$root}__icon {
            padding: 0;
            font-size: 1.4em;
        }
    }

    &--icon-at-right {
        display: inline-flex;
        flex-direction: row-reverse;

        #{$root}__icon {
            padding: 0 0 0 0.55em;
        }
    }

    &--no-wrap {
        white-space: nowrap;
    }

    &--rounded {
        border-radius: 100%;
    }

    &--center {
        display: inline-flex;
        justify-content: center;
    }

    &[disabled],
    &--disabled {
        cursor: default;
        background: var(--button--disabled-backgroundColor);
        color: var(--button--disabled-color);
    }

    &--medium {
        padding: $button--medium-padding;

        &#{$root}--icon-only {
            padding: em(10) em(6.7);

            #{$root}__icon {
                font-size: 1.2em;
            }
        }
    }

    &--small {
        padding: em(8) em(7);

        &#{$root}--icon-only {
            padding: em(8) em(6.55);

            #{$root}__icon {
                font-size: em(13);
            }
        }
    }

    &__loading-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__loading {
        height: 1em;
        margin-top: -0.66em;
        transform: scale(0.8);
        width: 2em;
        margin-left: -0.15em;
    }

    &__icon {
        padding: $button__icon-padding;
    }
}
