.pagination {
    $root: &;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1em;

    &__pages {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    li {
        display: inline;
        padding: 0 0.5em;

        &--active {
            & > a {
                border-bottom: 2px solid var(--color-corp-id-2);
            }
        }
    }
}
