:root {
    --statusBullet--created-color: rgb(var(--color-status__created));
    --statusBullet--created-backgroundColor: rgb(var(--color-status__created));
    --statusBullet--submitted-color: rgb(var(--color-status__submitted));
    --statusBullet--submitted-backgroundColor: rgb(var(--color-status__submitted));
    --statusBullet--approved-color: rgb(var(--color-status__approved));
    --statusBullet--approved-backgroundColor: rgb(var(--color-status__approved));
    --statusBullet--paid-color: rgb(var(--color-status__paid));
    --statusBullet--paid-backgroundColor: rgb(var(--color-status__paid));
    --statusBullet--rejected-color: rgba(var(--color-status__rejected));
    --statusBullet--rejected-backgroundColor: rgb(var(--color-status__rejected));
    --statusBullet--on-hold-color: rgb(var(--color-status__on-hold));
    --statusBullet--on-hold-backgroundColor: rgb(var(--color-status__on-hold));
}

@mixin applyStatusBulletModifier($root, $modifier, $position) {
    &--#{$modifier} {
        color: var(--statusBullet--#{$modifier}-color);

        &:#{$position} {
            background-color: var(--statusBullet--#{$modifier}-backgroundColor);
        }
    }
}

@mixin applyBulletStyles($marginPosition) {
    content: '';
    display: inline-block;
    width: 0.5em;
    height: 0.5em;
    background: rgba(var(--color-black), 1);
    border-radius: 100%;
    transform: translateY(-0.06em);
    margin-#{$marginPosition}: 0.5em;
}

.status-bullet {
    $root: &;

    &--to-left {
        &:before {
            @include applyBulletStyles(right);
        }
    }

    &--to-right {
        &:after {
            @include applyBulletStyles(left);
        }
    }

    @include applyStatusBulletModifier($root, created, before);
    @include applyStatusBulletModifier($root, submitted, before);
    @include applyStatusBulletModifier($root, approved, before);
    @include applyStatusBulletModifier($root, paid, before);
    @include applyStatusBulletModifier($root, rejected, before);
    @include applyStatusBulletModifier($root, on-hold, before);

    @include applyStatusBulletModifier($root, created, after);
    @include applyStatusBulletModifier($root, submitted, after);
    @include applyStatusBulletModifier($root, approved, after);
    @include applyStatusBulletModifier($root, paid, after);
    @include applyStatusBulletModifier($root, rejected, after);
    @include applyStatusBulletModifier($root, on-hold, after);
}
