h1,
h2,
h3,
h4,
h5 {
    line-height: 1.5;
    &,
    &.primary-type {
        font-family: var(--font-primary-bold);
        font-weight: bold;
    }

    &.secondary-type {
        font-family: var(--font-secondary-bold);
        font-weight: bold;
    }
}

h1 {
    font-size: 3em;
}

h2 {
    font-size: 2em; //TODO check with designer if makes sense use this size instead of what is documented (due to title at register page)
}

h3 {
    font-size: 1.31em;
}

h4 {
    font-size: 1.125em;

    &,
    &.primary-type {
        font-family: var(--font-primary-bold);
        font-weight: bold;
    }
    &.secondary-type {
        font-family: var(--font-secondary-bold);
        font-weight: bold;
    }
}

h5 {
    font-size: 1em;

    &,
    &.primary-type {
        font-family: var(--font-primary-bold);
        font-weight: bold;
    }
    &.secondary-type {
        font-family: var(--font-secondary-bold);
        font-weight: bold;
    }
}

//optional usage
.heading {
    &--primary-type {
        &--light {
            font-family: var(--font-primary);
        }
    }

    &--secondary-type {
        &--light {
            font-family: var(--font-secondary);
        }
    }
}
