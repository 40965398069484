.checkbox {
    $root: &;
    color: $selectbox-color;

    &__label {
        display: flex;
    }
    &__text {
        font: $selectbox__text-font;
        display: flex;
        position: relative;
        padding: $selectbox__text-padding;

        &:before {
            position: absolute;
            content: '';
            top: 0.1em;
            left: 0;
            width: 1em;
            height: 1em;
            display: block;
            background: $color-white;
            border-radius: $selectbox-border-radius;
            border: $selectbox__item-border;
        }

        &:after {
            position: absolute;
            content: '';
            top: 0.1em;
            left: 0;
            width: 1em;
            height: 1em;
            background: url('../img/correct-symbol.svg') no-repeat center/0.65em;
            opacity: 0.6;
            transform: scale(0);
            transition: all 0.2s;
        }
    }
    &__item {
        opacity: 0;
        transform: scale(0);
        position: absolute;

        &:focus {
            & + #{$root}__text:before {
                border-color: #a6a9ab;
            }
        }
        &:checked {
            & + #{$root}__text:after {
                transform: scale(1);
            }
        }

        &[disabled] {
            & + #{$root}__text {
                color: $color-foreground-d-25;
                &:before {
                    border-color: #d1d5d8;
                }

                &:after {
                    opacity: 0.3;
                }
            }
        }
    }

    &__message {
        font-size: $font-size-form-notification;
        padding: $selectbox__message-padding;
        @include clearfix();
    }
}
