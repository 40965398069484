@mixin list--timeline($root, $bullet-size) {
    $track-reference: 3em;
    $padding-reference: 0.8em;

    &#{$root}--timeline {
        overflow: hidden;
        position: relative;

        #{$root}__secondary-info {
            font-family: $font-lato;
            display: block;
        }

        #{$root}__list {
            font-family: $font-lato-bold;

            dt,
            dd {
                color: darken($color-gray, 30%);
                border-top: 1px solid lighten($color-gray, 10%);
                padding: $padding-reference;
                position: relative;

                &:first-of-type {
                    border: none;
                }

                &:last-of-type {
                    border: none;
                    background: $color-white;
                }
            }

            dd {
                &#{$root}__item {
                    &--overview {
                        color: var(--color-corp-id-3);
                    }
                }
            }

            dt {
                margin-left: $track-reference;

                &:before {
                    content: '';
                    display: block;
                    width: 1px;
                    height: 100%;
                    background: lighten($color-gray, 5%);
                    left: -#{$track-reference/2};
                    position: absolute;
                    top: 2em;
                }

                &#{$root}__item {
                    &--overview {
                        margin-left: 0;
                        padding-left: $track-reference + $padding-reference;

                        &:after {
                            background: var(--color-corp-id-3);
                            border: transparent;
                            left: calc(#{$track-reference/2} - #{$bullet-size/2});
                        }

                        &:before {
                            height: calc(50% - #{$bullet-size/2});
                            top: 0;
                            left: #{$track-reference/2};
                            background: none;
                            border: transparent;
                            border-right: 1px dashed $color-gray;
                        }
                    }
                }

                &:nth-last-child(4) {
                    &:before {
                        height: 100%;
                        top: 0;
                    }
                }

                &:after {
                    content: '';
                    width: $bullet-size;
                    height: $bullet-size;
                    border: 1px solid $color-gray;
                    background: lighten($color-gray, 5%);
                    display: block;
                    position: absolute;
                    left: calc(-#{$track-reference/2} - #{$bullet-size/2});
                    top: 1.3em;
                    border-radius: 1em;
                }
            }
        }
    }
}
