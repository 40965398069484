:root {
    --borderRadius: 0.187em;
    --boxShadow: 0 1px 2px 1px rgba(var(--color-black), 0.1);
    --boxShadow--spread: 0 0 50px 0 rgba(var(--color-black), 0.2);
    --font-primary: 'Remo Pro', Helvetica, sans-serif;
    --font-primary-bold: 'Remo Pro Bold', Helvetica, sans-serif;
    --font-secondary: 'Lato', Monaco, sans-serif;
    --font-secondary-bold: 'Lato Bold', sans-serif;
    --font-size-form-notification: 0.8em;
    --canvas-background: rgba(var(--color-canvas__neutral), 1);
}

pre {
    white-space: pre;
    padding: 0.3em;
    border: 1px solid rgba(var(--color-black), 0.1);
    background: rgba(var(--color-white), 0.7);
}

body {
    @include font-base();
    font-size: 16px;
    background: var(--canvas-background);
    color: var(--text-color);
}

p {
    margin-bottom: 1em;
}

strong {
    font-family: var(--font-primary-bold);
}

.type-secondary {
    font-family: var(--font-secondary);

    strong {
        font-family: var(--font-secondary-bold);
    }

    &--color {
        color: rgba(var(--color-gray__darken20), 1);
    }
}

a {
    color: rgba(var(--color-secondary__neutral), 1);
    text-decoration: none;

    &:visited {
        color: rgba(var(--color-secondary__darken50), 1);
    }

    &:hover,
    &:active {
        color: rgba(var(--color-secondary__darken50), 1);
        font-weight: var(--font-primary-bold);
    }
}

.table-overview {
    border-collapse: collapse;

    th {
        font-size: 0.85em;
        color: rgba(var(--color-gray__darken20), 1);
        font-family: var(--font-secondary-bold);
        text-align: left;
        padding: 0.5em;
    }

    td {
        padding: 0.5em;
    }
}

small,
.small {
    font-size: 0.93em;
}

.extra-small {
    font-size: 0.72em;
}

.hide {
    display: none !important;
}

.layover {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0);
    z-index: 3;
}

.project-info {
    transition: all 0.4s;
    display: flex;
    align-items: center;
    font-size: 0.9em;

    &.show {
        bottom: 0;

        .dev-mode-btn {
            bottom: -5em;
        }
    }

    .dev-mode-btn {
        transition: all 0.4s;
        border-radius: 100%;
        position: fixed;
        right: 0.5em;
        bottom: 1em;
        background: #000;
        line-height: 0;
        width: 2em;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 60%;
            margin: 0;
        }
    }

    img {
        max-width: 32px;
        margin-right: 1em;
    }

    div {
        flex: 1;
        padding-right: 2em;
        display: block;
        position: relative;
    }

    .close-dev-mode {
        position: absolute;
        transition: all 0.4s;
        border-radius: 100%;
        right: -0.5em;
        background: #000;
        line-height: 0;
        width: 2em;
        height: 2em;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.2em;
        top: -5px;
    }

    background: rgba(#000, 0.8);
    color: $color-white;
    position: fixed;
    bottom: -9em;
    left: 0;
    padding: 1em;
    width: 100%;

    b {
        font-family: $font-lato-bold;
    }

    a {
        &,
        &:visited {
            color: $color-white;
            text-decoration: none;
        }
    }
}
